/**
 * @description 로그 태그용 , next removeConsole 옵션은 명시적으로 정의해야 동작해서 분기처리함.
 * @param {string} tag
 * @param {'log' | 'info' | 'error' | 'warn'} level
 * @return {(...rest: any[]) => void}
 */
export const logWithTag = (
  tag: string,
  level?: 'log' | 'info' | 'error' | 'warn'
): ((...rest: any[]) => void) => {
  const args = `[ ${tag} ]`
  level = level ?? 'log'
  return (...rest: any[]) => {
    let f = console.log.bind(null, args)
    if (level === 'log') console.log(args, ...rest)
    else if (level === 'info') console.info(args, ...rest)
    else if (level === 'error') console.error(args, ...rest)
    else if (level === 'warn') console.warn(args, ...rest)
    // if (typeof window === 'undefined') f?.(args, JSON.stringify({ log: rest }));
    // else f?.(args, ...rest);
  }
}
