import { SkeletonContent } from '@/components'

import * as styles from './skeleton.css'

function MainSkeleton() {
  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex' }}>
        <SkeletonContent style={{ width: '56px', height: '56px' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginLeft: '16px' }}>
          <SkeletonContent style={{ width: '188px', height: '25px' }} />
          <SkeletonContent style={{ width: '113px', height: '25px' }} />
        </div>
      </div>
      <SkeletonContent style={{ width: '100%', height: '80px', marginTop: '20px' }} />
      <SkeletonContent style={{ width: '113px', height: '25px', marginTop: '34px' }} />
      <SkeletonContent style={{ width: '100%', height: '80px', marginTop: '14px' }} />
      <SkeletonContent style={{ width: '100%', height: '136px', marginTop: '32px' }} />
      <SkeletonContent style={{ width: '113px', height: '25px', marginTop: '34px' }} />
      <SkeletonContent style={{ width: '158px', height: '25px', marginTop: '14px' }} />
      <SkeletonContent style={{ width: '158px', height: '25px', marginTop: '14px' }} />
      <SkeletonContent style={{ width: '158px', height: '25px', marginTop: '14px' }} />
      <SkeletonContent style={{ width: '158px', height: '25px', marginTop: '14px' }} />
    </div>
  )
}

export { MainSkeleton }
