import * as React from 'react'

import { ToastContext } from '@/Providers'

export function useToast() {
  const toastContext = React.useContext(ToastContext)

  if (!toastContext) {
    throw new TypeError('`useToast`는 `ToastProvider` 내에서 호출해야 합니다.')
  }

  return toastContext
}
