import { HTMLAttributes } from 'react'

import * as styles from './skeleton.css'

interface Props extends HTMLAttributes<HTMLDivElement> {}

function SkeletonContent(props: Props) {
  const { style } = props
  return (
    <div
      className={styles.skeleton}
      style={style}
    />
  )
}

export { SkeletonContent }
