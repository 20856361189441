import cx from 'classnames'
import * as React from 'react'

import styles from '../Modal.module.scss'
import { ModalContainerProps } from './types'

export const ModalIcon = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { className, ...otherProps } = props

    return (
      <div
        {...otherProps}
        ref={ref}
        className={cx([styles.icon, className])}
      />
    )
  }
)
ModalIcon.displayName = 'ModalIcon'

export const ModalTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>((props, ref) => {
  const { className, ...otherProps } = props

  return (
    <h1
      {...otherProps}
      ref={ref}
      className={cx([styles.title, className])}
    />
  )
})
ModalTitle.displayName = 'ModalTitle'

export const ModalContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { className, ...otherProps } = props

    return (
      <div
        {...otherProps}
        ref={ref}
        className={cx([styles.content, className])}
      />
    )
  }
)
ModalContent.displayName = 'ModalContent'

export const ModalContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & ModalContainerProps
>((props, ref) => {
  const { icon, title, content, className, ...otherProps } = props

  return (
    <div
      {...otherProps}
      ref={ref}
      className={cx([styles.container, className])}
    >
      {icon && <ModalIcon>{icon}</ModalIcon>}
      {title && <ModalTitle>{title}</ModalTitle>}
      {content && <ModalContent>{content}</ModalContent>}
    </div>
  )
})
ModalContainer.displayName = 'ModalContainer'
