import { init as initFrontmanClient } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { TmapLog } from '@tmap-web-lib/tmap-log-client'
import { getSearchParams } from '@tmap-web-lib/utils'
import { AxiosResponse } from 'axios'
import { toLower } from 'lodash-es'
import process from 'process'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UAParser } from 'ua-parser-js'

import { useTmapLogContext } from '@/Providers/tmap-log-context'
import { initMockClient } from '@/apis'
import { sendEventLog } from '@/features/log/log.fn'
import {
  STORAGE_ACCESS_KEY,
  getSettingsState,
  setBizPlaceBanner,
  setRemoteConfigServiceList,
  updateAccessKey,
} from '@/features/store/settings'
import { staticBizPlaceBanner, staticServiceList, storageUtil } from '@/utils'

const ua = UAParser()
const InitializationProvider = ({ children }: PropsWithChildren<unknown>) => {
  const dispatch = useDispatch()
  const store = useSelector(getSettingsState)
  const { tmapLogClient } = useTmapLogContext()

  const [isReady, setReady] = useState(false)

  const init = useCallback(() => {
    Promise.all([
      TmapApp.getUserSetting({ key: 'local.server_type' }),
      TmapApp.getTmapInfo(),
      TmapApp.getAccessKey(),
      TmapApp.getRedDotList(),
    ]).then(([serverType, tmapInfo, accessKey]) => {
      const isPrd = process.env.NEXT_PUBLIC_BUILD_ENV === 'PRD' && serverType !== 'rtg'
      if (serverType === 'rtg') {
        if (
          process.env.NEXT_PUBLIC_TARGET === 'prd' &&
          process.env.NEXT_PUBLIC_BUILD_ENV !== 'RTG'
        ) {
          window.location.replace('https://tmobi-my-rtg.tmapmobility.com')
          return
        } else if (
          process.env.NEXT_PUBLIC_TARGET === 'stg' &&
          process.env.NEXT_PUBLIC_BUILD_ENV !== 'DTG'
        ) {
          window.location.replace('https://tmobi-my-dtg.tmapmobility.com')
          return
        }
      }

      let { deviceId, carrier, euk, sessionId } = tmapInfo || {}
      const reg = /^[a-zA-Z\d]*$/
      carrier = reg.test(carrier ?? '') ? carrier : 'ETC'
      const ak = accessKey || getSearchParams().ak || storageUtil.getItem(STORAGE_ACCESS_KEY) || ''
      euk = euk || ''
      dispatch(updateAccessKey(ak))

      initFrontmanClient({
        hostUrl: process.env.NEXT_PUBLIC_FRONTMAN_URL,
        headers: {
          Carrier: carrier ?? '',
          'Device-Id': deviceId ?? '',
          'App-Code': 'TMAP',
          'Os-Type': TmapApp.env.isAndroid ? 'AND' : 'IOS',
          'App-Version': TmapApp.env.appVersion,
          'Os-Version': ua.os.version || '',
          'Model-No': ua.device.model || '',
          Using: 'WEB_MY',
          Euk: euk,
        },
        timeout: 10000,
        onRequest(config) {
          config.headers.AccessKey = ak
          return config
        },
        onResponse(response) {
          return response
        },
        onAccessKeyChanged(newAccessKey: string) {
          if (newAccessKey) {
            TmapApp.updateAccessKey({ key: newAccessKey })
            dispatch(updateAccessKey(newAccessKey))
          }
        },
        onAccessKeyExpired(error) {
          if (TmapApp.env.isInApp) {
            TmapApp.getAccessKey().then((newAccessKey) => {
              if (newAccessKey.length) {
                TmapApp.updateAccessKey({ key: newAccessKey })
                dispatch(updateAccessKey(newAccessKey))
              } else {
                sendEventLog('my:error:frontman:onAccessKeyExpired', { error: error.response })
                TmapApp.logout({
                  reason: (error.response as AxiosResponse).data.error,
                })
              }
            })
          }
        },
        onError(error) {
          sendEventLog('my:error:frontman:onError', { error: error })
        },
      })

      initMockClient({
        onRequest(config) {
          config.headers.AccessKey = store.accessKey
          return config
        },
        onResponse(response) {
          return response
        },
        onAccessKeyChanged(newAccessKey: string) {
          if (newAccessKey) {
            TmapApp.updateAccessKey({ key: newAccessKey })
            dispatch(updateAccessKey(newAccessKey))
          }
        },
        onAccessKeyExpired(e) {
          console.log('onMockAPIAccessKeyExpired === ', e)
        },
        onError(e) {
          console.log('onMockAPIError', e)
        },
      })

      TmapLog.init({
        serviceId: 'my',
        appVersion: TmapApp.env.appVersion,
        carrier: carrier,
        deviceId: deviceId,
        sessionId: sessionId,
        logbox: {
          env: isPrd ? 'PRD' : 'STG',
          token: isPrd ? 'F5CD39ECE530' : '3AF19605D63E',
          appId: 'tmap',
          logVersion: `1.0-${toLower(process.env.NEXT_PUBLIC_BUILD_ENV)}`,
        },
        mixpanel: {
          token: isPrd ? 'cafac3a4974386155257d177fc48437a' : '7600c413c33bbd7e3742b10f1a522f0d',
          identity: euk,
          debug: !isPrd,
        },
        onSend: (tmapLogBuilder) => {
          tmapLogBuilder
            .setPageType('WEB')
            .setCustom('deploy_env', process.env.NEXT_PUBLIC_BUILD_ENV)
            .setEuk(euk)
        },
      })

      process.env.NEXT_PUBLIC_TARGET !== 'prd'
        ? TmapApp.getRemoteConfig({ key: 'my_service_list_STG' })
            .then((data) => {
              if (data) {
                dispatch(setRemoteConfigServiceList(data))
              } else {
                // remote config 값이 string으로 내려와서 변환 필요
                dispatch(setRemoteConfigServiceList(JSON.stringify(staticServiceList)))
              }
            })
            .catch((error) => {
              dispatch(setRemoteConfigServiceList(JSON.stringify(staticServiceList)))
              sendEventLog(
                error.response?.data
                  ? `my:error:getRemoteConfig:${error.response.data}`
                  : 'my:error:getRemoteConfig',
                { error: error }
              )
            })
        : TmapApp.getRemoteConfig({ key: 'my_service_list' })
            .then((data) => {
              if (data) {
                dispatch(setRemoteConfigServiceList(data))
              } else {
                dispatch(setRemoteConfigServiceList(JSON.stringify(staticServiceList)))
              }
            })
            .catch((error) => {
              dispatch(setRemoteConfigServiceList(JSON.stringify(staticServiceList)))
              sendEventLog(
                error.response?.data
                  ? `my:error:getRemoteConfig:${error.response.data}`
                  : 'my:error:getRemoteConfig',
                { error: error }
              )
            })

      process.env.NEXT_PUBLIC_TARGET !== 'prd'
        ? TmapApp.getRemoteConfig({ key: 'my_biz_place_banner_STG' })
            .then((data) => {
              if (data) {
                dispatch(setBizPlaceBanner(data))
              } else {
                dispatch(setBizPlaceBanner(JSON.stringify(staticBizPlaceBanner)))
              }
            })
            .catch((error) => {
              dispatch(setBizPlaceBanner(staticBizPlaceBanner.toString()))
              sendEventLog(
                error.response?.data
                  ? `my:error:getRemoteConfig:${error.response.data}`
                  : 'my:error:getRemoteConfig',
                { error: error }
              )
            })
        : TmapApp.getRemoteConfig({ key: 'my_biz_place_banner' })
            .then((data) => {
              if (data) {
                dispatch(setBizPlaceBanner(data))
              } else {
                dispatch(setBizPlaceBanner(JSON.stringify(staticBizPlaceBanner)))
              }
            })
            .catch((error) => {
              dispatch(setBizPlaceBanner(JSON.stringify(staticBizPlaceBanner)))
              sendEventLog(
                error.response?.data
                  ? `my:error:getRemoteConfig:${error.response.data}`
                  : 'my:error:getRemoteConfig',
                { error: error }
              )
            })

      setReady(true)
    })
  }, [dispatch, store.accessKey])

  useEffect(() => {
    init()
  }, [init])

  return isReady ? <>{children}</> : null
}

export { InitializationProvider }
