export const storageUtil = {
  getObjectItem(key: string): Record<string, any> {
    try {
      return JSON.parse(this.getItem(key) || '{}')
    } catch {
      return {}
    }
  },
  setObjectItem(key: string, data: Record<string, any>) {
    this.setItem(key, JSON.stringify(data))
  },
  getArrayItem<Data>(key: string): Data[] {
    try {
      return JSON.parse(this.getItem(key) || '[]')
    } catch {
      return []
    }
  },
  setArrayItem(key: string, data: any[]) {
    this.setItem(key, JSON.stringify(data))
  },
  getItem(key: string) {
    return localStorage.getItem(key)
  },
  setItem(key: string, value: string) {
    localStorage.setItem(key, value)
  },
}
