import { useModalManager } from '@tmap-web-lib/modal-manager'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'

function ErrorNotifier({ error }: { error: AxiosError | undefined }) {
  const modalManager = useModalManager()
  const router = useRouter()
  // 동일한 에러가 여러번 dispatch 됐을때 무시하도록 error.code만 확인.
  const errorCode = error?.response?.status

  // useEffect(() => {
  //   if (error) {
  //     console.log('ErrorNotifier', error)
  //     let message: ReactNode
  //
  //     // if (window.location.pathname !== '/web') {
  //     //   // modalManager.showModal<string>({
  //     //   //   render: ({ close, backdropRef, setBackdrop }) => {
  //     //   //     // return <MyDialogComponent
  //     //   //     //   backgroundRef={backdropRef}
  //     //   //     //   // backgroundRef={setBackDrop} lazy 로딩 컴포넌트일때
  //     //   //     //   onOk={() => {
  //     //   //     //     close('/page/path')
  //     //   //     //   }}
  //     //   //     // />
  //     //   //     return <>팝업</>
  //     //   //   },
  //     //   //   onClosed: (closedBy, passedValueByClose) => {
  //     //   //     // passedValueByClose 타입이 string으로 추론됩니다.
  //     //   //     if (closedBy === 'BACK_PRESSED') {
  //     //   //       // popstate 이벤트로 modal 닫힘.
  //     //   //     } else if (closedBy === 'CLICK_OUTSIDE') {
  //     //   //       // modal 바깥 클릭으로 닫힘.
  //     //   //     } else if (passedValueByClose) {
  //     //   //       // 모달 닫고 페이지 이동할땐 onClosed 에서.
  //     //   //       router.push(passedValueByClose)
  //     //   //     }
  //     //   //   },
  //     //   // })
  //     //   console.log('모달!!!!')
  //     //   modalManager.showModal({ render: () => <>팝업 테스트</> })
  //     // } else {
  //     //   return <Error error={error} />
  //     // }
  //     return <Error error={error} />
  //   }
  // }, [error])
}

export { ErrorNotifier }
