import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AxiosError } from 'axios'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useCallback, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider as ReduxProvider } from 'react-redux'

import {
  GlobalLoadingSpinnerProvider,
  GlobalModalManagerProvider,
  InitializationProvider,
  PageIdContextProvider,
} from '@/Providers'
import { Error, FrameLayout } from '@/components'
import DevTools from '@/components/DevTools'
import { sendEventLog } from '@/features/log/log.fn'
import { wrapper } from '@/features/store'
import { CheckPageId } from '@/routes'
import '@/styles/fonts.css'
import '@/styles/index.scss'

const devToolEnabled = process.env.NEXT_PUBLIC_DEBUG_VIEW === 'Y'

function App({ Component, ...rest }: AppProps) {
  const [error, setError] = useState<AxiosError>()
  const { store, props } = wrapper.useWrappedStore(rest)
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 60 * 1000,
            useErrorBoundary: true,
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            console.log(error)
          },
        }),
      })
  )

  const handleError = useCallback((error: AxiosError) => {
    setError((prev) => {
      if (!prev) return error
      if (prev.response?.status !== error.response?.status) return error
    })
    // useErrorBoundary: true 설정된 에러 트래킹
    sendEventLog('my:error:errorBoundary', { error: error })
  }, [])

  return (
    <>
      <Head>
        <title>마이</title>
        <meta
          name="description"
          content="마이 서비스"
        />
        <meta
          name="apple-mobile-web-app-capable"
          content="yes"
        />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="default"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <meta
          httpEquiv="Cache-control"
          content="no-cache,no-store,must-revalidate"
        />
        <link
          rel="icon"
          href="/favicon.ico"
        />
      </Head>

      <ReduxProvider store={store}>
        <InitializationProvider>
          <QueryClientProvider client={queryClient}>
            <PageIdContextProvider>
              <GlobalModalManagerProvider>
                <ErrorBoundary
                  onError={(error) => handleError(error as AxiosError)}
                  fallback={<Error error={error} />}
                >
                  <CheckPageId>
                    <GlobalLoadingSpinnerProvider offsetTop={'FULL'}>
                      <FrameLayout>
                        <Component {...rest.pageProps} />
                      </FrameLayout>

                      {devToolEnabled && <DevTools />}
                      <ReactQueryDevtools position={'bottom-right'} />
                    </GlobalLoadingSpinnerProvider>
                  </CheckPageId>
                </ErrorBoundary>
              </GlobalModalManagerProvider>
            </PageIdContextProvider>
          </QueryClientProvider>
        </InitializationProvider>
      </ReduxProvider>
    </>
  )
}

export default App
