import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit'

export const rtkQueryResolve: Middleware =
  (api: MiddlewareAPI) => (next) => async (action: any) => {
    if (!isRejectedWithValue(action)) {
      // rtk-query 요청이 있을때만 처리한다.
      if (action.type.includes('fulfilled') && action.meta.baseQueryMeta) {
        try {
          // const { headers } = action.meta.baseQueryMeta.response;
        } catch (e) {
          // console.log('rtkQueryResolve:resolve', e); // fix 보안검사
        }
      }
    }
    return next(action)
  }
