import * as ToastPrimitives from '@radix-ui/react-toast'
import * as React from 'react'

import styles from './Toast.module.scss'
import { ToastProps } from './types'

export function Toast(props: ToastProps) {
  const { open, onOpenChange, description } = props

  return (
    <ToastPrimitives.Provider duration={3000}>
      <ToastPrimitives.Root
        open={open}
        onOpenChange={onOpenChange}
        className={styles.base}
      >
        <ToastPrimitives.Description className={styles.description}>
          {description}
        </ToastPrimitives.Description>
      </ToastPrimitives.Root>
      <ToastPrimitives.Viewport />
    </ToastPrimitives.Provider>
  )
}
