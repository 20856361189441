import { DeviceDisplayInfo } from '@tmap-web-lib/tmap-app-interface'
import { atom } from 'jotai'

export type WebEnvState = {
  accessKey?: string
  carrierName?: string
  deviceId?: string
  euk?: string
  sessionId?: string
  displayInfo?: DeviceDisplayInfo
}

export const webEnvState = atom<WebEnvState | null>(null)
