import IcoArrowDownBold from '../../../public/assets/images/tmds/ico_arrow_down_bold.svg'
import IcoArrowRight from '../../../public/assets/images/tmds/ico_arrow_right.svg'
import IcoExit from '../../../public/assets/images/tmds/ico_exit.svg'
import IcoLocationNow from '../../../public/assets/images/tmds/ico_location_now.svg'
import IcoMapPin from '../../../public/assets/images/tmds/ico_map_pin.svg'
import IcoMapPinFavoriteFill from '../../../public/assets/images/tmds/ico_map_pin_favorite_fill.svg'
import IcoNotice from '../../../public/assets/images/tmds/ico_notice.svg'
import IcoPenWrite from '../../../public/assets/images/tmds/ico_pen_write.svg'
import IcoShare from '../../../public/assets/images/tmds/ico_share.svg'

export {
  IcoArrowDownBold,
  IcoArrowRight,
  IcoExit,
  IcoLocationNow,
  IcoMapPin,
  IcoMapPinFavoriteFill,
  IcoNotice,
  IcoPenWrite,
  IcoShare,
}
