import { AxiosError } from 'axios'
import Image from 'next/image'

import IconSign from '@/assets/images/sign_caution_2.svg'

import * as style from './error.css'

interface Props {
  error: AxiosError | undefined
}

function Error({ error }: Props) {
  if (!error?.response) return null
  const { status } = error.response

  const refresh = () => {
    window.location.reload()
  }

  return (
    <div className={style.wrapper}>
      <Image
        src={IconSign}
        alt={''}
      />
      <p className={style.title}>
        {status === 401 ? (
          <>
            사용자 인증정보가
            <br />
            변경되었습니다.
          </>
        ) : (
          <>
            일시적으로 서비스를
            <br />
            이용할 수 없습니다.
          </>
        )}
      </p>
      <p>잠시 후 다시 시도해주세요.</p>
      <button
        className={style.btn}
        onClick={refresh}
      >
        새로고침
      </button>
    </div>
  )
}

export { Error }
