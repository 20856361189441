import { PropsWithChildren, useEffect, useState } from 'react'

import { usePageIdContext } from '@/Providers'
import { useOpenServiceUrl } from '@/hooks'

interface Props extends PropsWithChildren<{}> {}

function CheckPageId(props: Props) {
  const { children } = props
  const pageIdContext = usePageIdContext()
  const [isReady, setReady] = useState(!pageIdContext.pageId)
  const openServiceUrl = useOpenServiceUrl()

  useEffect(() => {
    const { extraObject, pageIdInfo, searchParams } = pageIdContext
    const encodedExtraObject = encodeURIComponent(JSON.stringify(extraObject))
    if (pageIdInfo) {
      const { url, pageId } = pageIdInfo
      let path = `${url}?extra=${encodedExtraObject}`
      openServiceUrl(path)
    }
    setReady(true)
  }, [pageIdContext, openServiceUrl])

  return isReady ? <>{children}</> : null
}

export { CheckPageId }
