import { EnhancedStore, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createWrapper } from 'next-redux-wrapper'
import { useDispatch } from 'react-redux'

import { ENV_FEATURE_KEY, envReducer } from '@/features/store/env/env.slice'
import { rtkQueryReject } from '@/features/store/middleware/service.reject'
import { rtkQueryResolve } from '@/features/store/middleware/service.resolve'
import { SETTINGS_FEATURE_KEY, settingsReducer } from '@/features/store/settings'

export const store: any = configureStore({
  reducer: {
    [ENV_FEATURE_KEY]: envReducer,
    [SETTINGS_FEATURE_KEY]: settingsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkQueryReject, rtkQueryResolve),
})

const setupStore = (context: any): EnhancedStore => store

const makeStore = (context: any) => setupStore(context)

setupListeners(store.dispatch)

export type AppStore = ReturnType<typeof makeStore>
type AppDispatch = AppStore['dispatch']
export type RootState = ReturnType<AppStore['getState']>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const wrapper = createWrapper<AppStore>(makeStore)
