import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import * as React from 'react'

export const RADIO_VARIANT_OPTIONS = ['icon', 'none'] as const
export const RADIO_SIZE_OPTIONS = ['small', 'medium'] as const

export type RadioVarian = (typeof RADIO_VARIANT_OPTIONS)[number]
export type RadioSize = (typeof RADIO_SIZE_OPTIONS)[number]

export interface RadioGroupProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {}

export interface RadioItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  /**
   * @default icon
   * */
  variant?: RadioVarian
  /**
   * @default small
   * */
  size?: RadioSize
}
