import cx from 'classnames'
import { motion } from 'framer-motion'
import * as React from 'react'

import styles from '../Modal.module.scss'
import { ModalFrameProps } from '../types'

export const ModalFrame = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & ModalFrameProps
>((props, ref) => {
  const {
    variant,
    sheet,
    backdropRef,
    container,
    children,
    footer,
    className,
    style,
    ...otherProps
  } = props

  const transitionStyles = {
    toTop: {
      initial: { opacity: 0, y: '100%', scale: 0.95 },
      animate: { opacity: 1, y: '0%', scale: 1 },
      exit: { opacity: 0, y: '100%', scale: 0.95, transition: { delay: 0.1 } },
    },
    fade: {
      initial: { opacity: 0, scale: 0.95 },
      animate: { opacity: 1, scale: 1 },
      exit: { opacity: 0, scale: 0.95, transition: { delay: 0.1 } },
    },
    backdrop: {
      initial: { opacity: 0 },
      animate: { opacity: 0.6 },
      exit: { opacity: 0, transition: { delay: 0.1 } },
    },
  }

  const classes = cx(styles.portal, className, 'tmds-modal', {
    [styles[`is-${variant}`]]: variant,
    [styles['is-sheet-cell']]: sheet?.cell,
    [styles[`is-sheet-scroll-${sheet?.scroll}`]]: sheet?.scroll,
    [styles['is-sheet-title-sticky']]: sheet?.titleSticky,
  })

  return (
    <>
      <div
        {...otherProps}
        ref={ref}
        className={classes}
      >
        <motion.div
          className={styles.layer}
          initial={transitionStyles[`${variant === 'sheet' ? 'toTop' : 'fade'}`].initial}
          animate={transitionStyles[`${variant === 'sheet' ? 'toTop' : 'fade'}`].animate}
          exit={transitionStyles[`${variant === 'sheet' ? 'toTop' : 'fade'}`].exit}
          transition={{
            duration: variant === 'sheet' ? 0.4 : 0.25,
            ease: [0.32, 0.72, 0, 1],
          }}
        >
          <div className={styles.wrapper}>
            {container || children}
            {footer}
          </div>
        </motion.div>
        <motion.div
          className={styles.backdrop}
          ref={backdropRef}
          initial={transitionStyles['backdrop'].initial}
          animate={transitionStyles['backdrop'].animate}
          exit={transitionStyles['backdrop'].exit}
          transition={{
            duration: variant === 'sheet' ? 0.4 : 0.25,
            ease: [0.32, 0.72, 0, 1],
          }}
        />
      </div>
    </>
  )
})
ModalFrame.displayName = 'ModalFrame'
