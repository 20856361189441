import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import os from 'os'
import process from 'process'
import { UAParser } from 'ua-parser-js'

export const ENV_FEATURE_KEY = 'ENV_FEATURE_KEY'
type StringOfBoolean = 'true' | 'false'
export type ITmapInfo = {
  activity?: string
  addressType?: number
  adid?: string
  ak?: string
  carFuel?: string
  carModel?: string
  carrier?: string
  deviceId?: string
  deviceName?: string
  displayHeight?: number
  displayWidth?: number
  euk?: string
  fontSize?: number
  ip?: string
  isNightModeMap?: string
  isSatelliteModeMap?: StringOfBoolean
  isShowFavorite?: StringOfBoolean
  isShowRecentlyDestination?: StringOfBoolean
  locale?: string
  navigationBarHeight?: number
  network?: number
  orientation?: number
  os?: string
  osVersion?: number
  packageName?: string // 'com.skt.tmap.ku'
  packageVersion?: string
  rotate?: number
  screenHeight?: number
  screenWidth?: number
  sessionId?: string
  statusBarHeight?: number
  tilt?: number
  timeStamp?: number
  vendor?: string
  webviewHeight?: number
  webviewWidth?: number
  x?: number
  y?: number
  zoom?: number
  loginMethod?: string
}

export type IStandby = 'stg' | 'prd' | 'dev' // | null;
export type IEnv = IStandby
export type ILocalServerType = 'rtg' | 'dtg'
export type IServerEnv = {
  ua: UAParser.IResult
  withTmap: boolean
  prod: boolean
  debug: boolean
  env: IEnv
  remote: boolean
  appVersion: string
  webUrl: string
  buildNumber: string
  addr?: string
  e2e?: boolean
}
/**
 * @description 서버 환경 변수들 확인시 사용
 * @param req
 */
export const getServerEnv = (req: any): IServerEnv => {
  const userAgent = req?.headers['user-agent'] || ''
  const withTmap = /\/Tmap*/.test(userAgent)
  const dev = process.env.NEXT_PUBLIC_TARGET === 'dev'
  const loc = process.env.NEXT_PUBLIC_DEBUG_VIEW === 'Y'
  const prd = process.env.NODE_ENV === 'production'
  const remote = !loc && withTmap
  const prodByRemote = prd && process.env.NEXT_PUBLIC_TARGET === 'prd'
  const prodByLocal = loc && process.env.NEXT_PUBLIC_TARGET === 'prd'
  const devByLocal = loc && process.env.NEXT_PUBLIC_TARGET === 'prd'
  const appReg = /(Tmap([\w+\/]+)?\/)([\d|.\w]+)(?=(|\s))/gm
  const ua = UAParser(userAgent)
  // Tmap 확인 후 잘못 됬을 경우 대비 기본 시작 버전을 8.7.0 디폴트로 세팅.
  let appVersion = appReg.exec(ua.ua) ? (RegExp.$3 ?? '10.2.0') : ''
  if (!prodByRemote && !withTmap) appVersion = '10.2.0'
  const port = process.env.PORT
  let network
  if (loc || devByLocal) {
    network = os.networkInterfaces().en0?.find((v) => {
      return v.family.toLowerCase() === 'ipv4'
    })
  }
  const debug = loc || dev
  const env = {
    ua,
    withTmap: withTmap,
    prod: prodByRemote || prodByLocal,
    debug,
    env: process.env.NEXT_PUBLIC_TARGET as IEnv,
    remote,
    appVersion,
    addr: network ? `http://${network.address}:${port}` : process.env.WEB_URL,
    webUrl:
      withTmap && network?.address
        ? `http://${network?.address}:${port}`
        : (process.env.WEB_URL as string),
    buildNumber: process.env.BUILD_NUMBER ?? '0',
    e2e: /e2e/.test(process.env.npm_lifecycle_script as string),
  }
  if (debug) {
    if (loc) {
      console.log('getServerEnv: req', req?.url, req?.query)
      console.log('getServerEnv: env', env)
    } else {
      console.log('getServerEnv: env', JSON.stringify(env))
    }
  }

  return env
}

export interface envState {
  loadingStatus?: 'not loaded' | 'loading' | 'loaded' | 'error'
  error?: string | undefined
  [p: string]: any // immer WritableDraft 타입 우회처리를 위해 속성 타입 설정
  tmapInfo?: ITmapInfo | undefined
}

export const initialEnvState: envState = {
  loadingStatus: 'not loaded',
  error: undefined,
  tmapInfo: undefined,
}

export const envSlice = createSlice({
  name: ENV_FEATURE_KEY,
  initialState: initialEnvState,
  reducers: {
    updateState: (state, action: PayloadAction<envState>) => {
      const payload = action.payload
      for (const i in payload) {
        state[i] = payload[i]
      }
    },
  },
})

export const envApi = createApi({
  reducerPath: 'envApi',
  tagTypes: ['tmapInfo', 'browser'],
  baseQuery: fetchBaseQuery({
    baseUrl: `/promotions`,
    prepareHeaders: (headers, { getState }) => {
      return headers
    },
  }),
  endpoints: (builder) => ({
    getGnbBanner: builder.query({
      query: ({ payKey, productionId }) => ({
        url: 'gnb-banners',
      }),
      transformResponse: (response: any) => {
        return response
      },
      providesTags: ['tmapInfo'],
    }),
  }),
})

export const { useGetGnbBannerQuery } = envApi
export const envReducer = envSlice.reducer
export const envActions = envSlice.actions
export const getEnvState = (rootState: any): envState => rootState[ENV_FEATURE_KEY]
