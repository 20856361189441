import { useCallback, useContext } from 'react'
// eslint-disable-next-line no-restricted-imports
import { trackPromise } from 'react-promise-tracker'

import { GlobalLoadingSpinnerContext, Options } from './GlobalLoadingSpinnerProvider'

export function useGlobalLoadingSpinner() {
  const { setOptions } = useContext(GlobalLoadingSpinnerContext)

  return useCallback(
    <T>(promise: Promise<T>, { offsetTop = 'WITHOUT_HEADER' }: Options = {}) => {
      setOptions({ offsetTop })
      return trackPromise<T>(promise)
    },
    [setOptions]
  )
}
