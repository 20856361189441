import { AdType } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { TmapLogBuilder } from '@tmap-web-lib/tmap-log-client'
import { useCallback } from 'react'

export interface TrackingValues {
  orientation: number
  vimpressionId: string
  trackingUrl: string
  materialId: string
}

function useAdsManager() {
  const initTrackingValues = useCallback(async (data: AdType) => {
    const displayInfo = await TmapApp.getDisplayInfo()
    const orientation = displayInfo?.orientation ?? 1
    const adItem = data
    let trackingUrl,
      materialId,
      vimpressionId = ''

    const trackingInfo = adItem.trackingEventUrls.find(
      (trackingInfo) => trackingInfo.key === 'vimp'
    )
    const material = adItem.materials[0]

    if (trackingInfo && material) {
      await import('uuid').then(({ v4 }) => {
        if (v4()) {
          vimpressionId = v4()
        }
      })
      trackingUrl = trackingInfo.value
      materialId = material.id
      return {
        orientation,
        vimpressionId,
        trackingUrl,
        materialId,
      } as TrackingValues
    }
  }, [])

  const handleBanner = useCallback((ad: AdType, eventLog: TmapLogBuilder, actionId: string) => {
    eventLog.set('action_id', actionId).setCustom('ad_code', ad.materials[0].id).send()
  }, [])

  return {
    initTrackingValues,
    handleBanner,
  }
}

export { useAdsManager }
