import { PageId, PageIdInfo } from '@/Providers'

export const pageIdInfos: { [Key in PageId]: PageIdInfo<Key> } = Object.freeze({
  main: {
    pageId: 'main',
    url: '/web',
    name: 'MY 메인',
  },
  all_services: {
    pageId: 'all_services',
    url: '/web/all_services',
    name: '전체 서비스',
  },
})
