import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { createInitialState } from '@/features/store/settings/create-initial-state'
import { storageUtil } from '@/utils'

type SettingsState = ReturnType<typeof createInitialState>

export const SETTINGS_FEATURE_KEY = 'SETTINGS_FEATURE_KEY'
export const STORAGE_ACCESS_KEY = 'STORAGE-ACCESS-KEY'
export const STORAGE_MOCK_ENABLED = 'STORAGE_MOCK-ENABLED'
export const STORAGE_SCHEME_URL = 'STORAGE-SCHEME-URL'
export const STORAGE_TOOLTIP_CLICKED = 'STORAGE-TOOLTIP-CLICKED'

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: createInitialState(),
  reducers: {
    updateAccessKey(state: SettingsState, action: PayloadAction<string>) {
      const accessKey = action.payload
      state.accessKey = accessKey
      storageUtil.setItem(STORAGE_ACCESS_KEY, accessKey)
    },
    setIsMockEnabled(state: SettingsState, action: PayloadAction<boolean>) {
      const isMockEnabled = action.payload
      state.isMockEnabled = isMockEnabled
      storageUtil.setItem(STORAGE_MOCK_ENABLED, isMockEnabled.toString())
    },
    setIsSkeleton(state: SettingsState, action: PayloadAction<boolean>) {
      state.isSkeleton = action.payload
    },
    setRemoteConfigServiceList(state: SettingsState, action: PayloadAction<string>) {
      state.remoteConfigServiceList = action.payload
    },
    setSchemeURL(state: SettingsState, action: PayloadAction<string>) {
      const schemeExtraValue = action.payload
      state.schemeURL = schemeExtraValue
      storageUtil.setItem(STORAGE_SCHEME_URL, schemeExtraValue)
    },
    setBizPlaceBanner(state: SettingsState, action: PayloadAction<string>) {
      state.bizPlaceBanner = action.payload
    },
    setToolTipClicked(state: SettingsState, action: PayloadAction<boolean>) {
      const isTooltipClicked = action.payload
      state.isTooltipClicked = isTooltipClicked
      storageUtil.setItem(STORAGE_TOOLTIP_CLICKED, isTooltipClicked.toString())
    },
  },
})

export const {
  updateAccessKey,
  setIsMockEnabled,
  setIsSkeleton,
  setRemoteConfigServiceList,
  setSchemeURL,
  setBizPlaceBanner,
  setToolTipClicked,
} = settingsSlice.actions
export const getSettingsState = (rootState: any): SettingsState => rootState[SETTINGS_FEATURE_KEY]

export const settingsReducer = settingsSlice.reducer
