function createInitialState() {
  return {
    accessKey: '',
    carrierName: '',
    deviceId: '',
    euk: '',
    sessionID: '',
    accessKeyExpired: false,
    isSkeleton: false,
    isMockEnabled: false,
    remoteConfigServiceList: '',
    schemeURL: '',
    bizPlaceBanner: '',
    isTooltipClicked: false,
  }
}

export { createInitialState }
