import { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren<{}> {}

function FrameLayout(props: Props) {
  const { children } = props

  return <main>{children}</main>
}

export { FrameLayout }
