import * as React from 'react'

import { Toast } from '@/components'

export type ToastContextType = {
  showToast: (description: string) => void
}

export const ToastContext = React.createContext<ToastContextType>({
  showToast: () => ({}),
})

export function ToastProvider({ children }: React.PropsWithChildren<{}>) {
  const [open, setOpen] = React.useState(false)
  const [description, setDescription] = React.useState('')
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>()

  return (
    <ToastContext.Provider
      value={{
        showToast: React.useCallback((description) => {
          setOpen(true)
          setDescription(description)
          clearTimeout(timeoutRef.current)
          timeoutRef.current = setTimeout(() => setOpen(false), 3000)
        }, []),
      }}
    >
      {children}
      <Toast
        open={open}
        onOpenChange={setOpen}
        description={description}
      />
    </ToastContext.Provider>
  )
}
