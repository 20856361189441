import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { getSearchParams } from '@tmap-web-lib/utils'
import isEmpty from 'lodash-es/isEmpty'
import { useCallback } from 'react'
import Url from 'url-parse'

import { PageIdSearchParams } from '@/Providers'
import { sendEventLog } from '@/features/log/log.fn'

function useOpenServiceUrl() {
  return useCallback((srcUrl: string, useOriginalUrl?: boolean, needAnotherPath?: boolean) => {
    if (!srcUrl) {
      return
    }

    const env = window.location.href.split('.')[0]
    let path

    // 메인 페이지에서 컨텐츠 배너 클릭 접근
    if (srcUrl.startsWith('tmap')) {
      const urlObject = new Url(srcUrl)
      const params = getSearchParams(urlObject.query || '')
      const searchParams = getSearchParams<PageIdSearchParams>()
      const { pageid, ...paramsExceptPageid } = searchParams
      const scrollToObj = { scroll_to: params.extra && JSON.parse(params.extra).scroll_to }
      const seqObj = { seq: params.extra && JSON.parse(params.extra).seq }
      const moveToObj = { move_to: params.extra && JSON.parse(params.extra).move_to }
      const defaultParams = `&extra=${encodeURIComponent(
        JSON.stringify({
          is_first_history: true,
          logbox: { origin: 'tab', type: 'tab' },
          ...scrollToObj,
          ...seqObj,
          ...moveToObj,
        })
      )}`
      const extraParams = `extra=${encodeURIComponent(
        JSON.stringify({
          ...JSON.parse(paramsExceptPageid.extra || '{}'),
          ...scrollToObj,
          ...seqObj,
          ...moveToObj,
        })
      )}`
      const mySearchParams = isEmpty(searchParams.extra) ? defaultParams : extraParams

      switch (params.pageid) {
        case 'all_services':
          path = `/web/all_services?${mySearchParams}`
          break
        default:
          path = ''
          break
      }
    } else {
      path = srcUrl
    }

    if (path) {
      if (useOriginalUrl) {
        if (needAnotherPath) {
          const href = window.location.href

          if (href.startsWith('https://tmobi-my-dev.')) {
            TmapApp.openServiceByUrl({
              url: `https://m-dev.tmobiweb.com${path}`,
              title: '',
              cacheControl: true,
              portraitonly: true,
            })
          } else if (
            href.startsWith('https://tmobi-my-dtg.') ||
            href.startsWith('https://tmobi-my-stg.')
          ) {
            TmapApp.openServiceByUrl({
              url: `https://m-stg.tmobiweb.com${path}`,
              title: '',
              cacheControl: true,
              portraitonly: true,
            })
          } else {
            TmapApp.openServiceByUrl({
              url: `https://m.tmobiweb.com${path}`,
              title: '',
              cacheControl: true,
              portraitonly: true,
            })
          }
        } else {
          TmapApp.openServiceByUrl({
            url: path,
            title: '',
            cacheControl: true,
            portraitonly: true,
          })
        }
      } else {
        TmapApp.openServiceByUrl({
          url: `${env}.tmapmobility.com${path}`,
          title: '',
          cacheControl: true,
          portraitonly: true,
        })
      }
    } else {
      TmapApp.makeToast({ msg: '일시적인 접속 오류로 복구 중입니다.' })
      sendEventLog('my:error:잘못된 경로로 새 웹뷰 띄움', { error: srcUrl })
    }
  }, [])
}

export { useOpenServiceUrl }
