import { useCallback, useEffect, useRef, useState } from 'react'

type IntersectHandler = (entry: IntersectionObserverEntry, observer: IntersectionObserver) => void

function useIntersectionObserver(
  onIntersect: IntersectHandler,
  options?: IntersectionObserverInit
) {
  const ref = useRef<HTMLDivElement>(null)
  const [isView, setIsView] = useState(false)
  const [top, setTop] = useState(0)

  const callback = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersect(entry, observer)
          setIsView(true)
          if (!top) {
            setTop(entry.boundingClientRect.top)
          }
        } else {
          setIsView(false)
        }
      })
    },
    [onIntersect, top]
  )

  useEffect(() => {
    if (!ref.current) return
    const observer = new IntersectionObserver(callback, options)
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [ref, options, callback])

  return { ref, isView, top }
}

export { useIntersectionObserver }
