import { Button } from '../Button'
import { ButtonGroupDir } from '../Layout'
import { ModalContainer, ModalFooter, ModalFrame } from './Elements'
import { ModalProps } from './types'

export function Modal(props: ModalProps) {
  const { backdropRef, variant = 'popup', sheet, title, content, className, action, cancel } = props

  const sheetProps = { ...defaultProps.sheet, ...sheet }
  const actionProps = { ...defaultProps.action, ...action }
  const cancelProps = { ...defaultProps.cancel, ...cancel }

  return (
    <ModalFrame
      backdropRef={backdropRef}
      variant={variant}
      sheet={sheet}
      className={className}
      container={
        <ModalContainer
          icon={sheetProps.icon}
          title={title}
          content={content}
        />
      }
      footer={
        <ModalFooter
          dir={sheetProps.dir as ButtonGroupDir}
          action={
            <Button
              disabled={actionProps.disabled}
              onClick={() => {
                actionProps.callback?.()
              }}
            >
              {actionProps.text}
            </Button>
          }
          cancel={
            <Button
              variant="secondary"
              palette="gray"
              disabled={cancelProps.disabled}
              onClick={() => {
                cancelProps.callback?.()
              }}
            >
              {cancelProps.text}
            </Button>
          }
        />
      }
    />
  )
}

const defaultProps = {
  sheet: {
    scroll: 'container',
    icon: undefined,
    titleSticky: false,
    cell: false,
    dir: 'rl',
  },
  action: {
    text: '확인',
    disabled: false,
    callback: () => {},
  },
  cancel: {
    text: '취소',
    disabled: false,
    callback: () => {},
  },
}
