import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { Dictionary, getSearchParams } from '@tmap-web-lib/utils'
import { useCallback } from 'react'
import Url from 'url-parse'

function useOpenService() {
  return useCallback((srcUrl: string, additionalParams?: any) => {
    if (!srcUrl) {
      return
    }

    const urlObject = new Url(srcUrl)
    const scheme = urlObject.protocol
    const serviceName = urlObject.hostname

    if (scheme.startsWith('tmap')) {
      if (srcUrl.includes('tmap://webview')) {
        const searchParams = getSearchParams(urlObject.query || '')
        const params = new URLSearchParams(searchParams.params)
        const obj: Dictionary = {}

        for (const [key, value] of Array.from(params.entries())) {
          obj[key] = value
        }

        if (searchParams.service && searchParams.id) {
          TmapApp.openInAppBrowser({
            service: searchParams.service,
            id: searchParams.id,
            params: obj,
          })
        }
      } else {
        const params = getSearchParams(urlObject.query || '')
        TmapApp.openServiceByName({
          serviceName,
          jsonData: additionalParams ? additionalParams : params,
        })
      }
    } else {
      TmapApp.openBrowser({ url: srcUrl })
    }
  }, [])
}

export { useOpenService }
