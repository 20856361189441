import cx from 'classnames'
import * as React from 'react'

import styles from '../Modal.module.scss'
import { ModalFooterProps } from './types'

export const ModalFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & ModalFooterProps
>((props, ref) => {
  const { action, cancel, dir = 'rl', className, ...otherProps } = props

  return (
    <div
      {...otherProps}
      ref={ref}
      className={cx(styles.footer, className, {
        [styles[`is-${dir}`]]: dir,
      })}
    >
      {action}
      {cancel}
    </div>
  )
})
ModalFooter.displayName = 'ModalFooter'
