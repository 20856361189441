import cx from 'classnames'
import * as React from 'react'

import styles from './Layout.module.scss'

export const CONTENT_VARIANT_OPTIONS = ['center', 'full'] as const

export type ContentVariant = (typeof CONTENT_VARIANT_OPTIONS)[number]

interface LayoutContentProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: ContentVariant
}

export const LayoutContent = React.forwardRef<HTMLDivElement, LayoutContentProps>((props, ref) => {
  const { variant, children, className, ...otherProps } = props

  return (
    <div
      {...otherProps}
      ref={ref}
      className={cx(styles.content, className, {
        [styles[`is-${variant}`]]: variant,
      })}
    >
      {children}
    </div>
  )
})
LayoutContent.displayName = 'LayoutContent'
