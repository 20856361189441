import * as React from 'react'

export const BUTTON_VARIANT_OPTIONS = ['primary', 'secondary', 'stroke', 'naked'] as const
export const BUTTON_SHAPE_OPTIONS = ['square', 'circle'] as const
export const BUTTON_PALETTE_OPTIONS = ['blue', 'gray', 'danger'] as const
export const BUTTON_SIZE_OPTIONS = ['x-small', 'small', 'medium', 'large', 'x-large'] as const
export const BUTTON_WEIGHT_OPTIONS = ['normal', 'medium', 'bold'] as const

export type ButtonVariant = (typeof BUTTON_VARIANT_OPTIONS)[number]
export type ButtonShape = (typeof BUTTON_SHAPE_OPTIONS)[number]
export type ButtonPalette = (typeof BUTTON_PALETTE_OPTIONS)[number]
export type ButtonSize = (typeof BUTTON_SIZE_OPTIONS)[number]
export type ButtonWeight = (typeof BUTTON_WEIGHT_OPTIONS)[number]

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * @default false
   * */
  asChild?: boolean
  /**
   * @default primary
   * */
  variant?: ButtonVariant
  /**
   * @default square
   * */
  shape?: ButtonShape
  /**
   * @default blue
   * */
  palette?: ButtonPalette
  /**
   * @default x-large
   * */
  size?: ButtonSize
  /**
   * @default normal
   * */
  weight?: ButtonWeight
  /**
   * @default false
   * */
  fullWidth?: boolean
}
