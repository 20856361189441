import * as React from 'react'

import { ButtonGroupDir } from '../../Layout'
import { ModalVariant } from '../types'

export interface ModalContainerProps {
  icon?: React.ReactNode
  title?: string
  content?: string
}

export interface ModalFooterProps {
  action?: React.ReactNode
  cancel?: React.ReactNode
  dir?: ButtonGroupDir
}

export interface ModalWrapperProps {
  /**
   * @default popup
   */
  variant?: ModalVariant
  backdropRef?: any
}
