'use client'

function bootstrap() {
  // checkStageServerType();
}

if (typeof window !== 'undefined') {
  bootstrap()
}

export function ClientBootstrap() {
  return null
}
