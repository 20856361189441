import { useAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'

import { webEnvState } from '@/atoms'
import styles from '@/styles/components/Drawer.module.scss'

type DrawerStatePosition = 'TOP' | 'MIDDLE' | 'BOTTOM'

interface DrawerState {
  position: DrawerStatePosition
  posY: number
  isCurrentPosition: boolean
}

const MIDDLE_POSITION_PERCENT = 30 // 바텀시트 중간 위치 높이(%)
const BOTTOM_POSITION = 70 // 바텀시트 하단 위치
const TOP_TITLE_HEIGHT = 56 // 바텀시트가 최상단으로 올라 왔을 때의 상단 타이틀 높이
const HEADER_SORT_AREA_HEIGHT = 48 // 목록 개수, sorting 영역 높이 값

function useDrawer() {
  const [webEvn] = useAtom(webEnvState)

  const [movedPosY, setMovePosY] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const [originPosY, setOriginPosY] = useState(0)
  const [drawerState, setDrawerState] = useState<DrawerState[]>()
  const [drawerBoundaryPosY, setDrawerBoundaryPosY] = useState<number[]>()
  const [statusBarHeight] = useState<number>(webEvn?.displayInfo?.statusBarHeight || 0)
  const [titleOpacity, setTitleOpacity] = useState(0)
  const [topHeight] = useState(statusBarHeight + TOP_TITLE_HEIGHT)

  const refTop = useRef<HTMLDivElement | null>(null)
  const refContainer = useRef<HTMLDivElement | null>(null)
  const refHeader = useRef<HTMLDivElement | null>(null)
  const refVSM = useRef<HTMLDivElement | null>(null)
  const refAddLocation = useRef<HTMLButtonElement | null>(null)

  const getDrawerPosition = useCallback(
    (position: DrawerStatePosition) => {
      return drawerState?.map((item) => {
        if (item.position === position) {
          return { ...item, isCurrentPosition: true }
        }
        return { ...item, isCurrentPosition: false }
      })
    },
    [drawerState]
  )

  const moveToPosition = useCallback(
    (position: DrawerStatePosition) => {
      const result = getDrawerPosition(position)
      setDrawerState(result)

      if (position === 'BOTTOM') {
        refAddLocation.current!.style.display = 'none'
      } else {
        refAddLocation.current!.style.display = 'flex'
      }
    },
    [getDrawerPosition]
  )

  const handleMoving = useCallback(
    (e: TouchEvent | PointerEvent) => {
      e.preventDefault()
      const y = 'clientY' in e ? e.clientY : e.changedTouches[0].clientY
      const drawer = drawerState && drawerState.find((item) => item.isCurrentPosition)
      if (drawer?.position === 'BOTTOM' && drawer.posY < y) return

      setIsSwiping(true)
      setMovePosY(originPosY - y)
    },
    [drawerState, originPosY]
  )

  const handleMoveStart = useCallback((e: TouchEvent | PointerEvent) => {
    const y = 'clientY' in e ? e.clientY : e.changedTouches[0].clientY
    const target = e.target as HTMLElement
    setOriginPosY(y)
    setMovePosY(0)
    // refContainer.current!.addEventListener('pointermove', handleMoving, { passive: false });
  }, [])

  const handleMoveEnd = useCallback(
    (e: TouchEvent | PointerEvent) => {
      if (!drawerState || !drawerBoundaryPosY) return
      const isUp = movedPosY > 70
      const isDown = movedPosY < -70
      const drawer = drawerState.find((item) => item.isCurrentPosition)
      const currentPosY = refContainer.current!.offsetTop

      if (isUp) {
        if (currentPosY <= drawerBoundaryPosY[0]) {
          moveToPosition('TOP')
        } else {
          if (drawer?.position === 'BOTTOM') {
            moveToPosition('MIDDLE')
          } else if (drawer?.position === 'MIDDLE') {
            moveToPosition('TOP')
          }
        }
      } else if (isDown) {
        if (currentPosY >= drawerBoundaryPosY[1]) {
          moveToPosition('BOTTOM')
        } else {
          if (drawer?.position === 'TOP') {
            moveToPosition('MIDDLE')
          } else if (drawer?.position === 'MIDDLE') {
            moveToPosition('BOTTOM')
          }
        }
      }
      setIsSwiping(false)
    },
    [drawerState, drawerBoundaryPosY, movedPosY, moveToPosition]
  )

  useEffect(() => {
    if (!drawerState) return
    const elTop = refTop.current!
    const drawer = drawerState.find((item) => item.isCurrentPosition)

    if (drawer?.position === 'TOP') {
      elTop.classList.add(styles.show)
    } else {
      elTop.classList.remove(styles.show)
    }
  }, [drawerState])

  useEffect(() => {
    if (drawerState) {
      const container = refContainer.current!
      const vsm = refVSM.current!
      const drawer = drawerState.find((item) => item.isCurrentPosition)

      if (isSwiping) {
        const posY = -1 * movedPosY!
        container.style.top = `${(drawer?.posY || 0) + posY}px`
        container.style.transitionDuration = '0s'
        vsm.style.transitionDuration = '0s'
        vsm.style.transform = `translateY(${-(window.innerHeight - refContainer.current!.getBoundingClientRect().top) / 2}px)`
        return
      }

      container.style.top = `${drawer?.posY}px`
      container.style.transitionDuration = '0.3s'
      vsm.style.transitionDuration = '0.3s'
      vsm.style.transform = `translateY(${-(window.innerHeight - (drawer?.posY || 0)) / 2}px)`
    }
  }, [drawerState, isSwiping, movedPosY])

  useEffect(() => {
    const el = refHeader.current!

    el.addEventListener('touchstart', handleMoveStart, { passive: false })
    el.addEventListener('touchmove', handleMoving, { passive: false })
    el.addEventListener('touchend', handleMoveEnd, { passive: false })
    return () => {
      el.removeEventListener('touchstart', handleMoveStart)
      el.removeEventListener('touchmove', handleMoving)
      el.removeEventListener('touchend', handleMoveEnd)
    }
  }, [handleMoveStart, handleMoving, handleMoveEnd])

  useEffect(() => {
    if (drawerState) return
    const screenHeight = window.innerHeight
    const topHeight = refTop.current!.clientHeight
    const headerHeight = refHeader.current!.clientHeight
    const topPosY = -(headerHeight - topHeight - HEADER_SORT_AREA_HEIGHT)
    const middlePosY = (screenHeight * MIDDLE_POSITION_PERCENT) / 100
    const bottomPosY = screenHeight - BOTTOM_POSITION

    setDrawerState([
      {
        position: 'TOP',
        posY: topPosY,
        isCurrentPosition: false,
      },
      {
        position: 'MIDDLE',
        posY: middlePosY,
        isCurrentPosition: true,
      },
      {
        position: 'BOTTOM',
        posY: bottomPosY,
        isCurrentPosition: false,
      },
    ])
    setDrawerBoundaryPosY([middlePosY - topPosY, bottomPosY - middlePosY])
  }, [drawerState])

  return {
    refTop,
    refContainer,
    refHeader,
    refVSM,
    refAddLocation,
    drawerState,
    titleOpacity,
    topHeight,
    moveToPosition,
    statusBarHeight,
  }
}

export { useDrawer }
