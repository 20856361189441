import {
  BackNavigationController,
  ModalManagerProvider,
  NextJsBackNavigationControllerDefaults,
  useNextJsHandlePopStateEvent,
} from '@tmap-web-lib/modal-manager'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { PropsWithChildren, useMemo } from 'react'

export function GlobalModalManagerProvider({ children }: PropsWithChildren) {
  const backNavigationController = useMemo<BackNavigationController>(() => {
    let removeBackPressedListener: () => void
    return {
      ...NextJsBackNavigationControllerDefaults,
      onRegisterBackPressedListener: (listener) => {
        removeBackPressedListener = TmapApp.utils.addNativeEventListener<[boolean]>(
          'onBackKeyPressedEvent',
          (blocked) => {
            if (!blocked) return
            listener()
          }
        )
      },
      onDeregisterBackPressedListener: () => {
        removeBackPressedListener?.()
      },
      onNeedBlock: () => {
        TmapApp.handleBackKeyEventFromWeb({ handleFromWeb: true })
      },
      onNeedUnblock: () => {
        TmapApp.handleBackKeyEventFromWeb({ handleFromWeb: false })
      },
    }
  }, [])

  return (
    <ModalManagerProvider
      modalListWrapper={AnimatePresence}
      backNavigationController={backNavigationController}
      useHistoryApi={!TmapApp.env.isInApp || TmapApp.env.isIOS}
    >
      <NextJsPopStateEventListener />
      {children}
    </ModalManagerProvider>
  )
}

function NextJsPopStateEventListener() {
  const router = useRouter()
  useNextJsHandlePopStateEvent(router)
  return null
}
