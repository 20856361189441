import { TmapLog } from '@tmap-web-lib/tmap-log-client'

import { logWithTag } from '@/features/utils/console'

const LOG = logWithTag('log.fn')
export const sendEventLog = (actionId?: string, custom?: any, pageid?: string) => {
  let log = TmapLog('event').setPageId(pageid ? pageid : '/my')
  if (actionId) log.setActionId(actionId)
  if (custom) {
    Object.keys(custom).forEach((key) => {
      log.setCustom(key, custom[key])
    })
  }
  log.send().then((v) => {
    LOG('sendEvent ', { pageId: pageid ? pageid : '/my', actionId, custom })
  })
}
