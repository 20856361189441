// import { Transition } from '@/components/Transition'
// import { createTransitionStylesPreset } from '@/utils'
// import { css } from '@emotion/react'
// import { TmFlex } from '@tmap-web-lib/tmds-emotion'
import Image from 'next/image'
import { CSSProperties } from 'react'

import SpinnerIcon from '@/assets/images/icon-circular-blue-anim.svg'

interface Props {
  className?: string
  show: boolean
  duration?: number
  top?: CSSProperties['top']
  position?: CSSProperties['position']
}

export function LoadingSpinner(props: Props) {
  const { show, top = '0', position = 'absolute' } = props

  return (
    <>
      {show ? (
        <div
          style={{
            top: top,
            position: position,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            src={SpinnerIcon}
            alt={''}
            width={50}
            height={50}
          />
        </div>
      ) : null}
    </>
  )
}
