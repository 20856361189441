import Axios, { AxiosRequestConfig } from 'axios'

export interface AxiosConfig extends AxiosRequestConfig {
  accessKey?: string
  hostUrl?: string
  basePath?: string
}

export type Host = keyof typeof hostAxiosInstances

const timeout = 10000
const hostAxiosInstances = {
  mockHost: Axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_MOCK_SERVER_URL}`,
  }),
}

function initAxios({
  hostUrl = '',
  basePath = '',
  ...restConfig
}: Omit<AxiosConfig, 'url' | 'baseURL'>) {
  for (let host in hostAxiosInstances) {
    const hostAxiosInstance = hostAxiosInstances[host as Host]
    Object.assign(hostAxiosInstance.defaults, {
      timeout: timeout,
      ...restConfig,
    })
  }
}

export const { mockHost } = hostAxiosInstances
export { hostAxiosInstances, initAxios }
