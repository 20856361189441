import { classNames } from '@tmap-web-lib/utils'
import { PropsWithChildren } from 'react'

export type TmdsTagVariant = 'small' | 'regular' | 'bold' | 'capsule'
export type TmdsTagType =
  | 'solid_blue'
  | 'solid_green'
  | 'solid_pink'
  | 'solid_violet'
  | 'solid_red'
  | 'solid_ev'
  | 'solid_gray'
  | 'stroke_blue'
  | 'stroke_green'
  | 'stroke_pink'
  | 'stroke_violet'
  | 'stroke_red'
  | 'stroke_ev'
  | 'stroke_gray'

interface Props extends PropsWithChildren<{}> {
  variant: TmdsTagVariant
  type: TmdsTagType
  tagName: string
  color?: string
  className?: string
}

function Tag(props: Props) {
  const { variant, tagName, type, color, className } = props

  return <i className={classNames(`tmds-tag`, variant, type, className)}>{tagName}</i>
}

export { Tag }
