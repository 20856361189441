import cx from 'classnames'
import * as React from 'react'

import styles from './Layout.module.scss'

export const CONTAINER_VARIANT_OPTIONS = ['narrow', 'wide', 'full'] as const

export type ContainerVariant = (typeof CONTAINER_VARIANT_OPTIONS)[number]

export interface LayoutContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: ContainerVariant
}

export const LayoutContainer = React.forwardRef<HTMLDivElement, LayoutContainerProps>(
  (props, ref) => {
    const { variant = 'full', children, className, ...otherProps } = props

    const classes = cx(styles.container, className, {
      [`is-${variant}`]: variant,
    })

    return (
      <main
        {...otherProps}
        ref={ref}
        className={classes}
      >
        {children}
      </main>
    )
  }
)
LayoutContainer.displayName = 'LayoutContainer'
