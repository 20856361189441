import { Slot } from '@radix-ui/react-slot'
import cx from 'classnames'
import * as React from 'react'

import styles from './Button.module.scss'
import { ButtonProps } from './types'

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    asChild = false,
    variant = 'primary',
    shape = 'square',
    palette = 'blue',
    size = 'x-large',
    weight = 'normal',
    fullWidth = false,
    disabled = false,
    children,
    className,
    ...otherProps
  } = props

  const Comp = asChild ? Slot : 'button'
  const classes = cx(styles.base, 'tmds-button', className, {
    [styles[`is-variant-${variant}`]]: variant,
    [styles[`is-shape-${shape}`]]: shape,
    [styles[`is-palette-${palette}`]]: palette,
    [styles[`is-size-${size}`]]: size,
    [styles[`is-weight-${weight}`]]: weight,
    [styles['is-disabled']]: disabled,
    [styles['is-fullWidth']]: fullWidth,
  })

  return (
    <Comp
      {...otherProps}
      ref={ref}
      disabled={disabled}
      className={classes}
    >
      {children}
    </Comp>
  )
})

Button.displayName = 'Button'
