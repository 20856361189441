import cx from 'classnames'
import * as React from 'react'

import styles from './Layout.module.scss'

export const BUTTON_GROUP_DIR_OPTIONS = ['lr', 'rl', 'rl-unequal', 'tb'] as const

export type ButtonGroupDir = (typeof BUTTON_GROUP_DIR_OPTIONS)[number]

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * 버튼의 방향과 비율을 조정합니다.
   * @param {dir} lr - Left to right
   * @param {dir} rl - Right to left
   * @param {dir} tb - Top to bottom
   * @param {dir} bt - Bottom to top
   * @default rl
   * */
  dir?: ButtonGroupDir
}

/**
 * 페이지 하단에서 Button Atomic의 조합으로 배치되는 컴포넌트입니다.
 */
export const LayoutButtonGroup = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { dir = 'rl', children, className, ...otherProps } = props

  return (
    <div
      {...otherProps}
      ref={ref}
      className={cx(styles.bottomGroup, className, {
        [styles[`is-${dir}`]]: dir,
      })}
    >
      {children}
    </div>
  )
})
LayoutButtonGroup.displayName = 'LayoutButtonGroup'
