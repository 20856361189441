import * as styles from './switch.css'

interface Props {
  checked: boolean
  handleChange: (checked: boolean) => void
}

function Switch(props: Props) {
  const { checked = true, handleChange } = props

  return (
    <label className={styles.wrapper}>
      <input
        type="checkbox"
        className={styles.toggle}
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
      />
    </label>
  )
}

export { Switch }
