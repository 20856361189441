import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import cx from 'classnames'
import * as React from 'react'

import styles from './Radio.module.scss'
import { RadioGroupProps, RadioItemProps } from './types'

export const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupProps
>((props, ref) => {
  return (
    <RadioGroupPrimitive.Root
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = 'RadioGroup'

export const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioItemProps
>((props, ref) => {
  const { size = 'small', variant = 'icon', className, ...otherProps } = props

  const classes = cx(styles.base, className, {
    [styles[`is-${size}`]]: size,
    [styles[`is-${variant}`]]: variant,
  })

  return (
    <RadioGroupPrimitive.Item
      {...otherProps}
      ref={ref}
      className={classes}
    >
      {variant !== 'none' && (
        <RadioGroupPrimitive.Indicator
          forceMount
          className="indicator"
        >
          <i className="ico-chevron" />
        </RadioGroupPrimitive.Indicator>
      )}
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = 'RadioGroupItem'
