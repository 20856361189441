import { TmapLog, TmapLogBuilder, TmapLogType } from '@tmap-web-lib/tmap-log-client'
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useRef } from 'react'

import { useTmapLogContext } from './useTmapLogContext'

type Props = PropsWithChildren<Partial<Pick<ContextValue, 'pageId' | 'customs'>>>

export function TmapLogContextProvider({ children, pageId, customs }: Props) {
  const { pageId: parentPageId, customs: parentCustoms } = useTmapLogContext()

  const merged = useMemo(() => {
    const mergedPageId = pageId || parentPageId || ''
    const mergedCustoms = {
      ...parentCustoms,
      ...customs,
    }

    return {
      pageId: mergedPageId,
      customs: mergedCustoms,
    }
  }, [parentPageId, parentCustoms, pageId, customs])

  const mergedRef = useRef(merged)

  const tmapLogClient = useCallback((logType: TmapLogType) => {
    const tmapLogBuilder = TmapLog(logType)
    const { pageId, customs } = mergedRef.current

    if (pageId) {
      tmapLogBuilder.setPageId(pageId)
    }
    Object.keys(customs).forEach((key) => tmapLogBuilder.setCustom(key, customs[key]))
    return tmapLogBuilder
  }, [])

  const contextValue = useMemo(() => {
    return {
      ...merged,
      tmapLogClient,
    }
  }, [merged, tmapLogClient])

  useEffect(() => {
    mergedRef.current = merged
  }, [merged])

  return <TmapLogContext.Provider value={contextValue}>{children}</TmapLogContext.Provider>
}

type ContextValue = {
  pageId: string
  customs: Record<string, any>
  tmapLogClient: (logType: TmapLogType) => TmapLogBuilder
}

export const TmapLogContext = createContext<ContextValue>({
  pageId: '',
  customs: {},
  tmapLogClient: (logType: TmapLogType) => TmapLog(logType),
})
