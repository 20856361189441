import { useIsFetching } from '@tanstack/react-query'
import { ModalPortal } from '@tmap-web-lib/modal-manager'
import { debounce } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { LoadingSpinner } from '@/components'

import { GlobalLoadingSpinnerContext } from './GlobalLoadingSpinnerProvider'

export function GlobalLoadingSpinner() {
  const isFetching = useIsFetching()
  const { options } = useContext(GlobalLoadingSpinnerContext)

  const [isWorking, setIsWorking] = useState(isFetching > 0)
  const changeDebounce = useMemo(
    () =>
      debounce(
        (isWorking: boolean) => {
          setIsWorking(isWorking)
        },
        200,
        { leading: true }
      ),
    []
  )

  useEffect(() => {
    changeDebounce(isFetching > 0)
    return () => changeDebounce.cancel()
  }, [changeDebounce, isFetching])

  const top = useMemo(() => {
    switch (options.offsetTop) {
      case 'FULL':
        return '0'
      case 'WITHOUT_HEADER':
        return '56px'
      default:
        return options.offsetTop
    }
  }, [options.offsetTop])

  return (
    <ModalPortal elementId={'global-loading-spinner'}>
      <LoadingSpinner
        position={'fixed'}
        show={isWorking}
        top={top}
      />
    </ModalPortal>
  )
}
