import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { webEnvState } from '@/atoms'

const useClient = () => {
  const [, setWebEnv] = useAtom(webEnvState)

  useEffect(() => {
    Promise.all([
      TmapApp.getAccessKey(),
      TmapApp.getDeviceId(),
      TmapApp.getCarrierName(),
      TmapApp.getEUK(),
      TmapApp.getSessionId(),
    ]).then(([accessKey, deviceId, carrierName, euk, sessionId]) => {
      // 통신사 이름이 2바이트 문자열(일본어, 중국어)인 경우 브라우저에서 API 에러 발생하여 영문, 숫자를 제외한 나머지는 ETC로 변경(TMAPWEB-1583)
      const reg = /^[a-zA-Z\d]*$/
      const carrier = reg.test(carrierName) ? carrierName : 'ETC'

      setWebEnv({
        accessKey,
        deviceId,
        carrierName: carrier,
        sessionId,
      })
    })
  }, [setWebEnv])

  return {}
}

export default useClient
