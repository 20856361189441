import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit'

export const rtkQueryReject: Middleware = (api: MiddlewareAPI) => (next) => async (action) => {
  if (isRejectedWithValue(action)) {
    const { payload, meta } = action
    console.log('rtkQueryReject: action', action)
    const { arg } = meta
  }

  return next(action)
}
