import cx from 'classnames'
import * as React from 'react'

import styles from './Layout.module.scss'

export interface FrameLayoutProps extends React.HTMLAttributes<HTMLDivElement> {}

export const LayoutWrapper = React.forwardRef<HTMLDivElement, FrameLayoutProps>((props, ref) => {
  const { className, children, ...otherProps } = props

  return (
    <div
      {...otherProps}
      ref={ref}
      className={cx(styles.wrapper, className)}
    >
      {children}
    </div>
  )
})
LayoutWrapper.displayName = 'LayoutWrapper'
